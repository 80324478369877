<template>
  <v-card
    :outlined="!$vuetify.theme.dark"
    elevation="0"
    class="mx-2 hs-rounded-xl"
    :width="maxWidth"
    :max-height="548"
    :to="`/community/${id}`"
  >
    <v-container style="display: flex;">
      <v-img
        :src="getResourceUrl(thumbnail)"
        height="260px"
        width="260px"
        class="hs-rounded-xl"
      ></v-img>
    </v-container>

    <v-card-title
     class="text-truncate"
     style="display: block"
    >
      {{ name }}
    </v-card-title>

    <v-card-text
      class="text-caption"
      :style="`
        -webkit-line-clamp: 8; 
        height: ${cardTextHeight}em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      `"
    >
      {{ about }}
    </v-card-text>

    <v-card-actions>
      <v-badge
        dot
        left
        inline
        color="tertiary"
        class="ml-1"
      >
        <span
          class="caption adjustY"
          v-text="$t('onlineStatus.members', { amount: 80 })"
        ></span>
      </v-badge>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        :class="iconClass"
      >
        <v-icon :color="iconColor" small>{{ buzz ? mdiFire : mdiStarFourPointsOutline }}</v-icon>
      </v-btn>

      <v-avatar size="30">
        <v-img
          contain
          height="15"
          width="15"
          src="@assets/svgs/checkmark.svg"
        >
        </v-img>
      </v-avatar>
    </v-card-actions>
  
  </v-card>
</template>

<script>
  import { getResourceUrl } from '@utils'
  import { mapGetters } from 'vuex'
  import { mdiFire, mdiStarFourPointsOutline } from '@mdi/js'

  export default {
    props: {
      id: {
        type: String,
        default: ''
      },
      thumbnail: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      about: {
        type: String,
        default: ''
      }
    },

    mounted() {
      window.addEventListener('resize', this.getWidth)
    },

    data: () => ({
      mdiFire,
      mdiStarFourPointsOutline,
      width: window.innerWidth,
      buzz: Math.random() > 0.5 ? true : false // TODO: actually compute buzz
    }),

    computed: {
      ...mapGetters({
        innerWidth: 'innerWidth'
      }),

      iconColor() {
        return this.buzz ? 'orange darken-3' : ''
      },

      iconClass() {
        return this.buzz ? 'buzzing' : ''
      },

      maxWidth() {
        const multiplier = this.$vuetify.breakpoint.lgAndUp ? 0.72 : 0.95 
        return ((multiplier * this.innerWidth) / 4) + 'px'
      },

      cardTextHeight() {
        return this.width > 1400 ? 13 : 10
      }
    },
    
    methods: {
      getResourceUrl,
      getWidth() {
        this.width = window.innerWidth
      }
    }
  }
</script>

<style scoped>
.buzzing {
  box-shadow: 0px 0px 0.5px 0.5px #e9e7911a;
  background:#eeb90a25;
}
</style>
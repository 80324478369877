<template>
  <div class="px-6" style="padding-top: 3px;">
    <NewHall
      :updateOn="dialogOn"
      @close="dialogOn = false"
    />

    <v-row
      align="center"
      justify="center"
      class="py-2 px-4"
    >
      <v-btn
        elevation="0"
        style="border-radius: 10px !important;"
        :color="btnColor"
        @click="dialogOn = true"
      >
        {{ $t('community.createButtonLabel') }}
      </v-btn>

      <span class="mx-2"></span>
      
      <v-switch
        v-model="live"
        inset
        color="grey"
        style="transform: translateY(6px);"
      ></v-switch>

      <span>
        Live content
      </span>

      <span class="mx-1"></span>

      <span :class="live ? 'live-indicator' : 'status-indicator offline'"></span>
    
      <v-text-field
        v-model="search"
        filled
        rounded
        dense
        hide-details
        class="mx-4"
        style="border-radius: 12px"
        placeholder="Search content"
        :prepend-inner-icon="mdiMagnify"
        @input="setTyping"
      />
  
      <v-sheet
        :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'"
        style="border-radius: 12px; display: flex; justify-content: center; align-items: center; height: 40px; transition-duration: 0.5s;"
        class="switch-hall-style"
        width="90"
      >
        <v-btn-toggle
          v-model="gridStyle"
          :color="$vuetify.theme.dark ? 'grey lighten-2' : 'grey darken-3'"
          mandatory
          group
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
              >
                <v-icon>
                  {{ mdiCompassOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Explore Halls</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :color="`grey ${$vuetify.theme.dark ? 'darken' : 'lighten'}-1`"
              >
                <v-icon>
                  {{ mdiLibraryOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Library: coming soon</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-sheet>
    </v-row>

    <v-row justify="center">
      <v-col class="col-20">
        <v-card
          :outlined="!$vuetify.theme.dark"
          elevation="0"
          :height="`${$vuetify.breakpoint.xl ? 83 : 80}vh`"
          class="hs-rounded-xl"
          style="position: sticky; top: 30px;"
        >
          <Note
            v-for="(note, index) in notes"
            :key="index"
            :index="index"
            :note="note"
            :noteId="note.id"
          />

          <div class="my-8"></div>

          <div>
            <v-sheet
              class="mx-4"
              style="border-radius: 14px;"
              :color="optionColor"
            >
              <v-list-item>
                <v-list-item-content>
                  Verified only
                </v-list-item-content>
                
                <v-list-item-action>
                  <v-switch
                    color="tertiary"
                    inset
                    dense
                  ></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-sheet>
          </div>

          <div class="my-4"></div>

          <div>
            <v-sheet
              class="mx-4 clickable"
              v-ripple
              style="border-radius: 14px;"
              :color="optionColor"
              @click="doSomething"
            >
              <v-list-item>
                <v-list-item-content>
                  Invitations
                </v-list-item-content>
                
                <v-list-item-action>
                  <v-badge
                    overlap
                    color="red"
                    :value="10"
                  >
                    <span slot="badge">
                      {{ 10 }}
                    </span>
                    <v-icon>
                      {{ mdiSquareRoundedBadge }}
                    </v-icon>
                  </v-badge>
                </v-list-item-action>
              </v-list-item>
            </v-sheet>
          </div>

          <div class="my-2"></div>

          <v-container class="bottom-menu">
            <v-btn
              large
              elevation="0"
              :color="btnColor"
              style="border-radius: 12px !important; width: 100%;"
              @click="dialogOn = true"
            >
              create a hall
            </v-btn>
          </v-container>
        </v-card>
      </v-col>
      <v-col class="col-80 px-0 py-0">
        <ScrollPagination
          :requestData="{
            'url': `halls/${this.user.id}`,
            'params': {
              sort: {
                createdAt: -1
              }
            }
          }"
          :countData="{
            'url': `halls/${this.user.id}/count`,
          }"
          :thin="false"
          rootHeight="86vh"
          :rowClass="`justify-content: start;`"
          :rootClass="'row px-3'"
        >
          <template v-slot:child="props">
            <HallCard
              :key="`hall_${props.index}`"
              :id="props.item.id"
              :thumbnail="props.item.thumbnail"
              :name="props.item.name"
              :about="props.item.about"
              class="mb-4"
            />
          </template>
        </ScrollPagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NewHall from '@components/Hall/NewHall'
import { Note } from '@components/Note'
import ScrollPagination from '@components/App/ScrollPagination.vue'
import { SnackBar } from '@components/App'
import HallCard from '@components/Hall/Card'
import { v4 as uuidv4 } from 'uuid'
import API from '@api'

import {
  mdiMagnify,
  mdiViewList,
  mdiDotsGrid,
  mdiShimmer,
  mdiLibraryOutline,
  mdiGiftOutline,
  mdiAccountGroup,
  mdiCompassOutline,
  mdiSquareRoundedBadge
} from '@mdi/js'

export default {
  components: {
    ScrollPagination,
    SnackBar,
    HallCard,
    Note,
    NewHall
  },

  async mounted() {
    this.notes = [{
      sender: this.anna,
      id: uuidv4(),
      title: 'Welcome',
      paragraphs: [`Welcome to Halls! Here is your place to find communities to learn anything, meet new people and have lots of fun!`]
    }]
  },

  data() {
    return {
      mdiMagnify,
      mdiViewList,
      mdiDotsGrid,
      mdiShimmer,
      mdiLibraryOutline,
      mdiGiftOutline,
      mdiAccountGroup,
      mdiCompassOutline,
      mdiSquareRoundedBadge,

      dialogOn: false,
      notes: [],
      live: true,
      halls: [],
    }
  },

  computed: {
    ...mapGetters({
      anna: 'anna',
      user: 'user'
    }),

    btnColor() {
      return this.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'
    },

    optionColor() {
      return this.$vuetify.theme.dark ? '#333333' : '#f2f2f2'
    }
  },

  methods: {
    doSomething() {},
    async setCount() {
      const count = await API().get(`halls/${this.user.id}/count`)
      this.count = count
    },
  }
}
</script>

<style scoped>
/* max-width makes the columns shrink correctly */
.col-20 {
  flex: 0 0 20%;
  max-width: 20%
}

.col-80 {
  flex: 0 0 80%;
  max-width: 80%
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.v-btn {
  min-width: 30px !important;
  min-height: 30px !important; 
  border-radius: 6px !important;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 32px;
  width: 35px;
}
.v-btn__content {
  padding: 2px !important;
}

.v-btn .v-icon {
  font-size: 16px !important;
}

.switch-hall-style .v-btn--round {
 border-radius: 30% !important;
}

.search-field > .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(0, 0, 0, 0.04);
}

.search-field > .theme--light.v-text-field--filled > .v-input__control > .v-input__slot:hover {
  background: rgba(0, 0, 0, 0.06) !important;
}

.status-indicator {
  top: 10px;
  right: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.offline {
  background-color: #ccc;  
}

.theme--light .live-indicator {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #02ae02;
  box-shadow: 0 0 0 0 rgba(1, 186, 1, 0.7);
  animation: pulse 1.8s ease-in-out infinite;
}

.theme--dark .live-indicator {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #00ff00;
  box-shadow: 0 0 0 0 rgba(0,255,0,0.7);
  animation: pulse 1.8s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.487); 
  }
  33% {
    box-shadow: 0 0 0 6px rgba(0,255,0,0);
  }
  66% {
    box-shadow: 0 0 0 8px rgba(0,255,0,0);
  }
  100% {  
    box-shadow: 0 0 0 0 rgba(0,255,0,0);
  }
}
</style>